import React, { useState } from "react"
import styles from "./calculator.module.css"
import { Link } from "gatsby"

const ProfileContent = () => {
  const [submitStatus, setSubmitStatus] = useState("")
  const [interestRate, setInterestRate] = useState("")
  const [amount, setAmount] = useState("")
  const [time, setTime] = useState("")

  const getCreditScore = input => {
    setInterestRate(input.target.value)
    console.log(input)
  }
  const getAmount = input => {
    setAmount(input.target.value)
  }
  const getTimeLength = input => {
    setTime(input.target.value)
  }

  const setInterest = () => {
    let p = parseFloat(amount) // Principal Amount
    let n = parseFloat(time) // Number of Months
    let r = parseFloat(interestRate) / n // Interest Rate / Period

    // Amortization Rate Caluculation
    const monthlyInterest =
      p * ((r * Math.pow(r + 1, n)) / (Math.pow(r + 1, n) - 1))

    let mPay = document.getElementById("mPay")
    let rDiv = document.getElementById("rDiv")

    mPay.innerHTML = "$" + monthlyInterest.toFixed(2)

    if (!isNaN(monthlyInterest) && monthlyInterest > 0) {
      rDiv.style.display = "block"
      setSubmitStatus("success")
    } else if (monthlyInterest < 0) {
      rDiv.style.display = "none"
      setSubmitStatus("fail-negative")
    } else {
      rDiv.style.display = "none"
      setSubmitStatus("fail-noSelect")
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Link to="/dashboard">
          <div className={[styles.editButton, styles.button].join(" ")}>
            <div className={styles.button_text}>Back</div>
          </div>
        </Link>
        <h1 className={styles.title_text}>Monthly Estimates Calculator</h1>
      </div>
      <div className={styles.content}>
        <p className={styles.attribute_text}>
          What is your customer's credit score?
        </p>
        <div className={styles.cScore} onChange={getCreditScore}>
          <div className={styles.selection}>
            <input id="highScore" name="cScore" type="radio" value="0.118" />
            <label htmlFor="highScore">Excellent (700-850)</label>
          </div>
          <div className={styles.selection}>
            <input id="mediumScore" name="cScore" type="radio" value="0.174" />
            <label htmlFor="mediumScore">Good (640-700)</label>
          </div>
          <div className={styles.selection}>
            <input id="lowScore" name="cScore" type="radio" value="0.245" />
            <label htmlFor="lowScore">Building (Below 640)</label>
          </div>
        </div>

        <p className={styles.attribute_text}>
          How much is the customer looking to borrow?
        </p>
        <div className={styles.amount}>
          <div className={styles.icon}>$</div>
          <input
            id="borrowAmount"
            name="borrowAmount"
            type="number"
            placeholder="10000"
            className={styles.amount_input}
            onChange={getAmount}
          />
        </div>

        <p className={styles.attribute_text}>
          How long is the customer looking to borrow? (in MONTHS)
        </p>
        <div
          className={[styles.time, styles.cScore].join(" ")}
          onChange={getTimeLength}
        >
          <div className={styles.selection}>
            <input id="timeOne" name="timeLength" type="radio" value="12" />
            <label htmlFor="timeOne">12</label>
          </div>
          <div className={styles.selection}>
            <input id="timeTwo" name="timeLength" type="radio" value="24" />
            <label htmlFor="timeTwo">24</label>
          </div>
          <div className={styles.selection}>
            <input id="timeThree" name="timeLength" type="radio" value="36" />
            <label htmlFor="timeThree">36</label>
          </div>
          <div className={styles.selection}>
            <input id="timeFour" name="timeLength" type="radio" value="48" />
            <label htmlFor="timeFour">48</label>
          </div>
          <div className={styles.selection}>
            <input id="timeFive" name="timeLength" type="radio" value="60" />
            <label htmlFor="timeFive">60</label>
          </div>
        </div>

        {submitStatus === "success" ? (
          <div></div>
        ) : submitStatus === "fail-noSelect" ? (
          <div className={styles.fail}>
            <span className={styles.bold}>Error!</span> Please select all inputs
            before recieving a quote.
          </div>
        ) : submitStatus === "fail-negative" ? (
          <div className={styles.fail}>
            <span className={styles.bold}>Error!</span> Please enter a valid
            number for the borrowing amount.
          </div>
        ) : null}

        <div className={styles.box}>
          <div className={styles.button} onClick={setInterest}>
            <div className={styles.button_text}>Calculate</div>
          </div>
        </div>
        <div id="rDiv" className={styles.results_box}>
          <h3>Monthly Estimate</h3>
          <hr />
          <h4 id="mPay" className={styles.r}></h4>
        </div>
      </div>
    </div>
  )
}

export default ProfileContent
