import React from "react"

import Layout from "../components/layout"
import Menu from "../components/menu"
import Footer from "../components/footer"
import Calculator from "../components/calculator"

import Login from "./login"
import { withAuthenticator } from "aws-amplify-react"

const QuoteCalculator = () => (
  <Layout type={2}>
    <Menu type={2} btype={2} />
    <Calculator />
    <Footer type={3} />
  </Layout>
)

export default withAuthenticator(QuoteCalculator, false, [<Login />])
